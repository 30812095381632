import React, { Component } from "react";
import moment from "moment";
import ellipsize from "ellipsize";
import extend from "lodash/extend";
import {
  SearchkitManager,
  SearchkitProvider,
  SearchBox,
  RefinementListFilter,
  Pagination,
  HitsStats,
  SortingSelector,
  NoHits,
  ResetFilters,
  RangeFilter,
  ViewSwitcherToggle,
  GroupedSelectedFilters,
  Layout,
  TopBar,
  LayoutBody,
  LayoutResults,
  ActionBar,
  ActionBarRow,
  SideBar,
  Hits
} from "searchkit";
import "./index.css";

const searchkit = new SearchkitManager(
  `${process.env.REACT_APP_ES_HOST}/awesomefoundation-winners`,
  {
    basicAuth: `${process.env.REACT_APP_ES_USER}:${
      process.env.REACT_APP_ES_PASSWORD
    }`
  }
);

const DESC_LIMIT = 400;
const CLOUDINARY_ACCOUNT = "spantree-technology-group-llc";

const Thumbnail = props => {
  const { width, height, url, defaultUrl } = props;
  let thumbUrl = url
    ? `https://res.cloudinary.com/${CLOUDINARY_ACCOUNT}/image/fetch/w_${width},h_${height},c_thumb/${url}`
    : defaultUrl;
  thumbUrl = thumbUrl.replace(/\(/g, "%28").replace(/\)/g, "%29");
  return (
    <div className="image-bg" style={{ backgroundImage: `url(${thumbUrl})` }} />
  );
};

const HighlightedField = props => {
  const { result, fieldName, maxLength } = props;
  if (result.highlight && result.highlight[fieldName]) {
    const fieldHighlights = result.highlight[fieldName];
    const highlightHtml = ellipsize(
      fieldHighlights[0].replace(/^\W+/, "..."),
      maxLength
    );
    return <span dangerouslySetInnerHTML={{ __html: highlightHtml }} />;
  } else {
    return <span>{ellipsize(result._source[fieldName], maxLength)}</span>;
  }
};

const GrantHitsGridItem = props => {
  const { bemBlocks, result } = props;
  let url = result._source.awesome_project_url;
  const source = extend({}, result._source, result.highlight);
  let formatted_date;
  if (source.published_date) {
    formatted_date = moment(source.published_date).format("MMMM YYYY");
  }
  return (
    <div
      className={bemBlocks.item().mix(bemBlocks.container("item"))}
      data-qa="hit"
    >
      <div>
        <Thumbnail
          width={320}
          height={200}
          url={result._source.image_url}
          defaultUrl={
            "//d13mwkvpspjvzo.cloudfront.net/assets/no-image-index.png"
          }
        />
        <div data-qa="title" className={bemBlocks.item("title")}>
          <a href={source.awesome_project_url} target="_blank">
            <HighlightedField result={result} fieldName="project_name" />
          </a>
        </div>
        <div>
          <span data-qa="chapter" className="af-chapter">
            {source.chapter_name}
          </span>
          &nbsp;-&nbsp;
          <span data-qa="date" className="af-date">
            {formatted_date}
          </span>
        </div>
        <div data-qa="date" className="af-date" />
        <div data-qa="description" className={bemBlocks.item("description")}>
          <HighlightedField
            result={result}
            fieldName="project_description"
            maxLength={DESC_LIMIT}
          />
        </div>
      </div>
    </div>
  );
};

const dateFormatter = epoch => {
  return moment(epoch).format("MMMM YYYY");
};

class App extends Component {
  render() {
    return (
      <SearchkitProvider searchkit={searchkit}>
        <Layout>
          <TopBar>
            <div className="my-logo">
              <img
                alt=""
                className="fast-forward"
                src="//d13mwkvpspjvzo.cloudfront.net/assets/logo-14a63e88ede43df46a86df1ecf800919.png"
              />
              <h1>Catalog of Awesome</h1>
            </div>
            <SearchBox
              autofocus={true}
              searchOnChange={true}
              prefixQueryFields={["project_name^2", "project_description^1"]}
            />
          </TopBar>

          <LayoutBody>
            <SideBar>
              <RefinementListFilter
                id="countries"
                title="Countries"
                field="chapter_country.keyword"
                operator="OR"
                size={10}
              />
              <RefinementListFilter
                id="chapters"
                title="Chapters"
                field="chapter_name.keyword"
                operator="OR"
                size={10}
              />
              <RangeFilter
                id="published_date"
                title="Date Awarded"
                field="published_date"
                // May 1 2012 is the earliest publish date
                min={1335830400000}
                max={new Date().getTime()}
                interval={2629746000}
                showHistogram={true}
                rangeFormatter={dateFormatter}
              />
            </SideBar>
            <LayoutResults>
              <ActionBar>
                <ActionBarRow>
                  <HitsStats
                    translations={{
                      "hitstats.results_found":
                        "{hitCount} results found in {timeTaken}ms"
                    }}
                  />
                  <ViewSwitcherToggle />
                  <SortingSelector
                    options={[
                      {
                        label: "Most Relevant Projects First",
                        field: "_score",
                        order: "desc"
                      },
                      {
                        label: "Newest Projects First",
                        field: "published_date",
                        order: "desc",
                        defaultOption: true
                      },
                      {
                        label: "Oldest Projects First",
                        field: "published_date",
                        order: "asc"
                      }
                    ]}
                  />
                </ActionBarRow>

                <ActionBarRow>
                  <GroupedSelectedFilters />
                  <ResetFilters />
                </ActionBarRow>
              </ActionBar>
              <Hits
                hitsPerPage={21}
                highlightFields={["project_name", "project_description"]}
                customHighlight={{
                  number_of_fragments: 1,
                  fragment_size: DESC_LIMIT + 100
                }}
                sourceFilter={[
                  "project_name",
                  "project_description",
                  "awesome_project_url",
                  "image_url",
                  "chapter_name",
                  "chapter_country",
                  "published_date"
                ]}
                itemComponent={GrantHitsGridItem}
                mod="sk-hits-grid"
              />
              <NoHits suggestionsField={"project_name"} />
              <Pagination showNumbers={true} />
            </LayoutResults>
          </LayoutBody>
        </Layout>
      </SearchkitProvider>
    );
  }
}

export default App;
